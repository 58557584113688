import React, { useEffect, useState } from 'react';

import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';

import '@solana/wallet-adapter-react-ui/styles.css';
import '../components/SendSol.css';
import solLogo from "../media/sol.png";
import axios from 'axios';
import NavComponent from './Nav';
import { BASE_URL } from './Vars';

import { useNavigate } from 'react-router-dom';
interface ClaimAirdropProps {

}

const baseURL = BASE_URL;
const ClaimAirdrop: React.FC<ClaimAirdropProps> = ({ }: ClaimAirdropProps) => {
    const navigate = useNavigate();
    const [amount, setAmount] = useState<number>(0);
    const { connection } = useConnection();
    const { publicKey, sendTransaction, connected, wallet } = useWallet();
    const [whiteListStatus, setWhiteListStatus] = useState<boolean>(false);

    const [tokenName, setTokenName] = useState<string>('');
    const [tokenImage, setTokenImage] = useState<string>('');
    const [exchangeRate, setExchangeRate] = useState<number>(0);

    const [ownerWallet, setOwnerWallet] = useState<string>('');

    const [minBuy, SetMinBuy] = useState<number>(0.0);
    const [maxBuy, setMaxBuy] = useState<number>(0.0);

    const [stopSwap, setStopSwap] = useState<boolean>(false);
    const [swapMessage, setSwapMessage] = useState<string>("");


    const [activationStatus, setActivationStatus] = useState<boolean>(true);
    const [activationMessage, setActivationMessage] = useState<boolean>(true);
    const [redirectPage, setRedirectPage] = useState<string>("");

    useEffect(() => {
        axios.post(`${baseURL}/api/getDeactivateStatus/`, {
            "pageName": "claim_airdrop"
        }).then((res) => {
            var data = res.data;
            console.log("deactivation data ", data);

            setActivationStatus(!res.data.deactivate);
            setActivationMessage(res.data.msg);
            setRedirectPage(res.data.redirect_page);

        })
    }, [])
    useEffect(() => {
        console.log("redirect page ", redirectPage);
        if (redirectPage) {
            window.location.replace(redirectPage);
            // if (redirectPage == "presale") {
            //     navigate("/")
            // }
            // else if (redirectPage == "claim_airdrop") {
            //     navigate("/claim/")
            // }
            // else if (redirectPage == "swap") {
            //     navigate("/swap/")
            // }
        }
    }, [redirectPage])

    useEffect(() => {
        if (wallet?.adapter.name != "Phantom" && wallet != null) {
            setStopSwap(true);
            setSwapMessage("Only Phantom Wallet is allowed.");
        } else {
            setStopSwap(false);
        }
    }, [wallet])

    useEffect(() => {

        // Get the airdrop token
        axios.get(`${baseURL}/api/getAirdropToken/?t=${new Date().getTime()}`).then((response) => {
            console.log(response.data);
            setTokenName(response.data.name);
            setTokenImage(response.data.imageURL);
            setExchangeRate(response.data.rate);

        }).catch((error) => {
            console.error('Error : ', error);
        });

        // Get Airdrop Admin Wallet
        axios.get(`${baseURL}/api/getAirdropAdminWallet/?t=${new Date().getTime()}`).then((response) => {
            setOwnerWallet(response.data.address);

        }).catch((error) => {
            console.error('Error : ', error);
        });

        // Get Minimum Shares to withdraw

        // axios.get(`${baseURL}/api/getBuySellLimit/?t=${new Date().getTime()}`).then((response) => {
        //     SetMinBuy(response.data.minBuy == null ? 0 : response.data.minBuy);
        //     setMaxBuy(response.data.maxBuy == null ? 100000000000 : response.data.maxBuy);

        // }).catch((error) => {
        //     console.error('Error : ', error);
        // });
    }, [])

    useEffect(() => {

        // Get wallet public key
        const postData = {
            publicKey: publicKey?.toString()
        };



        // Get Airdrop Whitelist from admin
        fetch(`${baseURL}/api/getAirdropWhiteList/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add any additional headers if needed (e.g., Authorization)
            },
            body: JSON.stringify(postData)  // Send the data as JSON
        })
            .then(response => {

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();  // Parse the JSON response
            })
            .then(data => {
                const whitelistallow = data.allow;
                console.log(data);
                setWhiteListStatus(whitelistallow);
                setAmount(data.shares);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [publicKey]);


    const handleSendSol = async (event: React.FormEvent) => {
        event.preventDefault();


        if (!publicKey) {
            alert("Please connect your wallet !.");
            return;
        }
        try {

            console.log("receiver ", ownerWallet, " sender ", publicKey.toString())
            const lamports = parseInt((0.00005 * LAMPORTS_PER_SOL).toString());
            console.log("Lamports ", lamports);
            const recipientPubKey = new PublicKey(ownerWallet); // Owner wallet public Key
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: recipientPubKey,
                    lamports,
                })
            );
            const signature = await sendTransaction(transaction, connection)


            var postData = {
                signature: signature,
                "publicKey": publicKey

            }
            axios.post(`${baseURL}/api/confirmAirdropTransaction/?t=${new Date().getTime()}`, postData).then((response) => {
                console.log(response.data);
                var transactionSuccess = response.data.status == 200 && response.data.txStatus == "success";
                if (transactionSuccess) {
                    alert("\nCongratulations! Transaction was successful.\n\nYour tokens will arrive within 2 minutes.");
                }
            }).catch((error) => {
                console.error('Error : ', error);
            });
            console.log(`Transaction signature: ${signature}`);

            alert(`Transaction sent! Signature: ${signature}`);
        } catch (error) {
            console.error("Transaction failed:", error);
            alert(`Transaction failed: ${error}`);
        }
    };




    var buttonStyle1: React.CSSProperties = {
        padding: "12px",
        "height": "34px",
        flexShrink: 0,
        borderRadius: "16px",
        border: "1px solid #B1F82D",
        background: "rgba(177, 248, 45, 0.00)",
    }

    var buttonStyle2: React.CSSProperties = {
        width: "400px",

        "height": "51px",
        flexShrink: 0,
        borderRadius: "9px",
        border: "1px solid #85CA5B",
        background: "rgba(89, 120, 15, 0.37)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    }
    return (activationStatus ?
        <div className="form-container">
            {/* <div style={{ alignSelf: "center" }}><NavComponent /></div> */}
            <WalletMultiButton style={buttonStyle1} />

            <form onSubmit={handleSendSol} style={{ marginTop: '20px' }}>
                <div className="input-area">
                    <div className="box1">
                        <p className="t1" >Airdrop amount</p>
                        <div className="token-contract">
                            <img className='icon' src={tokenImage} />
                            <p className="t2">{tokenName}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill="#ACCFFB" />
                            </svg>
                        </div>
                    </div>
                    <div className="box2">
                        <input
                            type="text"
                            placeholder="0.0"
                            value={amount ? `${amount} Shares ${amount * exchangeRate} Token` : 0}
                            readOnly

                            style={{
                                fontSize: '20px'
                            }}
                        />
                    </div>
                </div>
                {

                    !stopSwap ?
                        (
                            !connected ? (
                                <WalletMultiButton style={buttonStyle2} />
                            ) : (
                                whiteListStatus ? (
                                    <button type="submit" className="swap-button">Claim Airdrop</button>
                                ) : (
                                    <div className="swap-button" style={{ backgroundColor: "rgba(255, 0, 0, 0.418)" }}>You are not in the whitelist.</div>
                                )

                            )
                        ) : (
                            <div className="swap-button" style={{ backgroundColor: "rgba(255, 0, 0, 0.418)" }}>{swapMessage}</div>
                        )
                }

            </form>

        </div> : <>
            <div className="form-container">
                {/* <div style={{ alignSelf: "center" }}><NavComponent /></div> */}
            </div >

        </>
    );
};

export default ClaimAirdrop;
