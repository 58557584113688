// export const BASE_URL = "http://127.0.0.1:8000";
export const BASE_URL = "https://whitelistpresale.xyz";
export interface tokenPairInterface {
    tokenA: string,
    tokenB: string;
    exchangeRate: number;
    internal_data: string;
    tokenAimg: string;
    tokenBimg: string;
    tokenA_address: string;
    tokenB_address: string;
    tokenA_is_token22: boolean;
    tokenB_is_token22: boolean;
    tokenA_decimals: number;
    tokenB_decimals: number;
}