import React, { useEffect, useState } from 'react';

import { Buffer } from 'buffer';
import solLogo from "../media/sol.png";
import token22Logo from "../media/token22.png";
import '../components/SendSol.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import style from './css/swap.module.css';
import "axios";



import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import { createTransferInstruction, createTransferCheckedInstruction, getAssociatedTokenAddress, getOrCreateAssociatedTokenAccount, TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import axios from 'axios';
import { red } from '@mui/material/colors';
import { BASE_URL } from './Vars';
import { tokenPairInterface } from './Vars';
import styles from "./css/sendSOL.module.css";
import NavComponent from './Nav';
import { useNavigate } from 'react-router-dom';


(window as any).Buffer = Buffer;

const baseURL = BASE_URL;


const SwapComponent: React.FC = () => {
    const [amount, setAmount] = useState<number>(0);
    const { connection } = useConnection();
    const { publicKey, sendTransaction, connected, wallet } = useWallet();
    const [whiteListStatus, setWhiteListStatus] = useState<boolean>(false);

    const [tokenName, setTokenName] = useState<string>('');
    const [tokenImage, setTokenImage] = useState<string>('');
    const [exchangeRate, setExchangeRate] = useState<number>(0);

    const [ownerWallet, setOwnerWallet] = useState<string>('');

    const [minBuy, SetMinBuy] = useState<number>(1000000000.0);
    const [maxBuy, setMaxBuy] = useState<number>(1000000000.0);

    const [stopSwap, setStopSwap] = useState<boolean>(false);
    const [swapMessage, setSwapMessage] = useState<string>("");

    const [totalBuys, setTotalBuys] = useState<number | null>(null);

    const [swapPairs, setSwapPairs] = useState<Array<tokenPairInterface>>([]);
    const [showPairList_01, setShowPairList_01] = useState<boolean>(false)
    const [showPairList_02, setShowPairList_02] = useState<boolean>(false)
    const navigate = useNavigate();
    const [currentPair, setCurrentPair] = useState<tokenPairInterface>({
        tokenA: "SOL",
        tokenB: "USDT",
        tokenAimg: "unknow",
        tokenBimg: "unknown",
        internal_data: "unknown",
        exchangeRate: 200,
        tokenA_address: "",
        tokenB_address: "",
        tokenA_is_token22: false,
        tokenB_is_token22: false,
        tokenA_decimals: 0,
        tokenB_decimals: 0
    });
    const [search, setSearch] = useState<string | null>(null);
    const filteredTokens = search || (search?.replaceAll(" ", "").length && search?.replaceAll(" ", "").length > 0) ? swapPairs.filter(

        (token) =>
            token.tokenA.toLowerCase().includes(search.replaceAll(" ", "").toLowerCase()) ||
            token.tokenB.toLowerCase().includes(search.replaceAll(" ", "").toLowerCase())
    ) : swapPairs;

    useEffect(() => {
        axios.get(`${baseURL}/api/getSwapables`).then((res) => {

            setSwapPairs(res.data)
            
        })
            .catch((err) => console.error("Error fetching swappables:", err));
    }, [])
    useEffect(()=>{
        swapPairs.length > 0 ? setCurrentPair(swapPairs[0]) : console.log("No pair found");
    },[swapPairs])

    useEffect(() => {
        if (wallet?.adapter.name != "Phantom" && wallet != null) {
            setStopSwap(true);
            setSwapMessage("Only Phantom Wallet is allowed.");
        } else {
            setStopSwap(false);
        }
    }, [wallet])
    useEffect(() => {

        // console.log("amount is chainging")

        // console.log(amount * exchangeRate, maxBuy * exchangeRate, totalBuys)
        // if (amount > maxBuy && amount != 0) {
        //     setSwapMessage("Please resolve warning message.");
        //     setStopSwap(true);
        //     alert(`\nMaximum purchase = ${maxBuy} SOL.\n\nMultiple purchases allowed per wallet,\nup to the cumulative, maximum amount.`);
        // }
        // else if (amount < minBuy && amount != 0) {
        //     setSwapMessage("Please resolve warning message.");
        //     setStopSwap(true);
        //     alert(`\nMinimum purchase =  ${minBuy} SOL.\n\nMultiple purchases allowed per wallet,\nup to the cumulative, maximum amount.`);
        // }

        // else if (amount * exchangeRate + (totalBuys ? totalBuys : 0) > maxBuy * exchangeRate) {
        //     setSwapMessage("Please resolve warning message.");
        //     setStopSwap(true);
        //     alert(`\nThis purchase exceeds the maximum amount allowed per wallet\n\nMultiple purchases allowed per wallet,\nup to the cumulative, maximum amount.`);
        // }
        // else {
        //     setStopSwap(false);
        // }

        // if (amount > 0) {
        //     var exchange_amount = amount * exchangeRate;

        //     axios.get(`${baseURL}/api/getBalance/?t=${new Date().getTime()}`).then((response) => {
        //         console.log(response.data);
        //         if (exchange_amount > response.data.balance) {
        //             setStopSwap(true);
        //             setSwapMessage("Not enough tokens remain to fulfill your order.");
        //         }
        //     }).catch((error) => {
        //         console.error('Error : ', error);
        //     });
        // }
    }, [amount])

    useEffect(() => {
        console.log("testing");
        axios.get(`${baseURL}/api/getToken/?t=${new Date().getTime()}`).then((response) => {
            console.log(response.data);
            setTokenName(response.data.name);
            setTokenImage(response.data.imageURL);
            setExchangeRate(response.data.rate);
        }).catch((error) => {
            console.error('Error : ', error);
        });

        axios.get(`${baseURL}/api/getWallet/?t=${new Date().getTime()}`).then((response) => {
            setOwnerWallet(response.data.address);
            console.log("owner wallet ", response.data.address)

        }).catch((error) => {
            console.error('Error : ', error);
        });

        // axios.get(`${baseURL}/api/getBuySellLimit/?t=${new Date().getTime()}`).then((response) => {
        //     SetMinBuy(response.data.minBuy == null ? 0 : response.data.minBuy);
        //     setMaxBuy(response.data.maxBuy == null ? 100000000000 : response.data.maxBuy);

        // }).catch((error) => {
        //     console.error('Error : ', error);
        // });



    }, [])


    useEffect(() => {
        console.log("testing");
        axios.get(`${baseURL}/api/getToken/?t=${new Date().getTime()}`).then((response) => {
            console.log(response.data);
            setTokenName(response.data.name);
            setTokenImage(response.data.imageURL);
            setExchangeRate(response.data.rate);
        }).catch((error) => {
            console.error('Error : ', error);
        });

        axios.get(`${baseURL}/api/getSwapWallet/?t=${new Date().getTime()}`).then((response) => {
            setOwnerWallet(response.data.address);

        }).catch((error) => {
            console.error('Error : ', error);
        });

        axios.get(`${baseURL}/api/getBuySellLimit/?t=${new Date().getTime()}`).then((response) => {
            SetMinBuy(response.data.minBuy == null ? 0 : response.data.minBuy);
            setMaxBuy(response.data.maxBuy == null ? 100000000000 : response.data.maxBuy);

        }).catch((error) => {
            console.error('Error : ', error);
        });
    }, [amount])





    useEffect(() => {
        const postData = {
            publicKey: publicKey?.toString()
        };

        console.log(postData);

        // Send POST request using fetch
        fetch(`${baseURL}/api/getSwapWhitelist/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add any additional headers if needed (e.g., Authorization)
            },
            body: JSON.stringify(postData)  // Send the data as JSON
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();  // Parse the JSON response
            })
            .then(data => {
                const whitelistallow = data.allow;
                console.log("whitelist ->" , data);
                setWhiteListStatus(whitelistallow);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [publicKey]);


    useEffect(() => {
        if (whiteListStatus == true && publicKey) {

            const getWalletData = async () => {

                console.log("wallet is in the whitelist");
                let postData = {
                    "wallet_address": publicKey?.toString()
                }
                fetch(`${baseURL}/api/getWalletData/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();  // Parse the JSON response
                    })
                    .then(data => {
                        const totalBuys = data.buys;
                        console.log(data);
                        setTotalBuys(totalBuys);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }

            getWalletData();
        }

    }, [whiteListStatus, amount])


    const handleSwapMechanism = async (event: React.FormEvent) => {
        event.preventDefault();


        if (!publicKey) {
            alert("Please connect your wallet !.");
            return;
        }
        try {
            const lamports = parseInt((amount * LAMPORTS_PER_SOL).toString());
            console.log("Lamports ", lamports);
            const recipientPubKey = new PublicKey(ownerWallet); // Owner wallet public Key
            const mint = new PublicKey(currentPair.tokenA_address);
            var transaction = null;
            if (currentPair.tokenA_is_token22) {
                const fromTokenAccount = await getAssociatedTokenAddress(mint, publicKey, false, TOKEN_2022_PROGRAM_ID);
                const toTokenAccount = await getAssociatedTokenAddress(mint, recipientPubKey, false, TOKEN_2022_PROGRAM_ID);
                transaction = new Transaction().add(
                    createTransferCheckedInstruction(fromTokenAccount, mint, toTokenAccount, publicKey, amount * 10 ** currentPair.tokenA_decimals , currentPair.tokenA_decimals, [], TOKEN_2022_PROGRAM_ID)
                );
                console.log("lamports ", lamports)
                console.log("mint ", mint.toString());
                console.log("public key ", publicKey.toString());
                console.log("receipent pub key ", recipientPubKey.toString())
                console.log("from token account ", fromTokenAccount.toString());
                console.log("to token account ", toTokenAccount.toString());
                console.log("transaction ", transaction)
                const mintInfo = await connection.getAccountInfo(mint);
                console.log("Token Mint Info:", mintInfo);
            } else {
                const fromTokenAccount = await getAssociatedTokenAddress(mint, publicKey, false, TOKEN_PROGRAM_ID);
                const toTokenAccount = await getAssociatedTokenAddress(mint, recipientPubKey, false, TOKEN_PROGRAM_ID);
                transaction = new Transaction().add(
                    createTransferCheckedInstruction(fromTokenAccount, mint, toTokenAccount, publicKey, amount * 10 ** currentPair.tokenA_decimals , currentPair.tokenA_decimals, [], TOKEN_PROGRAM_ID)
                );
                console.log("section 2");
                console.log("lamports ", lamports)
                console.log("mint ", mint.toString());
                console.log("public key ", publicKey.toString());
                console.log("receipent pub key ", recipientPubKey.toString())
                console.log("from token account ", fromTokenAccount.toString());
                console.log("to token account ", toTokenAccount.toString());
                console.log("transaction ", transaction)
                const mintInfo = await connection.getAccountInfo(mint);
                console.log("Token Mint Info:", mintInfo);
            }


            if (transaction) {
                const signature = await sendTransaction(transaction, connection);
                var postData = {
                    signature: signature,
                    "publicKey": publicKey,
                    "pairData": currentPair.internal_data

                }
                axios.post(`${baseURL}/api/swap/?t=${new Date().getTime()}`, postData).then((response) => {
                    console.log(response.data);
                    var transactionSuccess = response.data.status == 200 && response.data.txStatus == "success";
                    if (transactionSuccess) {
                        alert("\nCongratulations! Transaction was successful.\n\nYour tokens will arrive within 2 minutes.");
                    }
                }).catch((error) => {
                    console.error('Error : ', error);
                });
                console.log(`Transaction signature: ${signature}`);

                alert(`Transaction sent! Signature: ${signature}`);
            } else {
                alert("Transaction reverted")
            }



        } catch (error) {
            console.error("Transaction failed:", error);
            alert(`Transaction failed: ${error}`);
        }
    };




    var buttonStyle1: React.CSSProperties = {
        padding: "12px",
        "height": "34px",
        flexShrink: 0,
        borderRadius: "16px",
        border: "1px solid #B1F82D",
        background: "rgba(177, 248, 45, 0.00)",
    }

    var buttonStyle2: React.CSSProperties = {
        width: "400px",

        "height": "51px",
        flexShrink: 0,
        borderRadius: "9px",
        border: "1px solid #85CA5B",
        background: "rgba(89, 120, 15, 0.37)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    }



    const [activationStatus, setActivationStatus] = useState<boolean>(true);
    const [activationMessage, setActivationMessage] = useState<boolean>(true);
    const [redirectPage, setRedirectPage] = useState<string>("");
    


    useEffect(() => {
        axios.post(`${baseURL}/api/getDeactivateStatus/`, {
            "pageName": "swap"
        }).then((res) => {
            var data = res.data;
            console.log("deactivation data ", data);

            setActivationStatus(!res.data.deactivate);
            setActivationMessage(res.data.msg);
            setRedirectPage(res.data.redirect_page);
        })
    }, [])
    useEffect(()=>{
        console.log("redirect page ",redirectPage);
        if(redirectPage){
            window.location.replace(redirectPage);
            // if(redirectPage == "presale"){
            //     navigate("/")
            // }
            // else if(redirectPage == "claim_airdrop"){
            //     navigate("/claim/")
            // }
            // else if (redirectPage == "swap"){
            //     navigate("/swap/")
            // }
        }
    },[redirectPage])



    return (
        activationStatus ?
        <div className={styles.form_container}>
            {/* <div style={{alignSelf:"center"}}><NavComponent/></div> */}
            <WalletMultiButton style={buttonStyle1} />

            <form onSubmit={handleSwapMechanism} style={{ marginTop: '20px' }}>
                <div className={styles.input_area}>
                    <div className={styles.box1}>
                        <p className={styles.t1}>You are sending</p>
                        <div className={styles.token_contract} onClick={(e) => { setShowPairList_01(!showPairList_01) }}>
                            <img className='icon' src={currentPair.tokenAimg} alt="Sol Logo" />
                            <p className={styles.t2}>{currentPair.tokenA}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill="#ACCFFB" />
                            </svg>
                        </div>

                    </div>
                    {
                        showPairList_01 ? (
                            <>
                                <div className={style.dropdownSwappables}>
                                    <form action="" className={style.searchPair}>
                                        <div className={style.search_area}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M11.4333 12.25L7.75833 8.575C7.46667 8.80833 7.13125 8.99306 6.75208 9.12917C6.37292 9.26528 5.96944 9.33333 5.54167 9.33333C4.48194 9.33333 3.58507 8.96632 2.85104 8.23229C2.11701 7.49826 1.75 6.60139 1.75 5.54167C1.75 4.48194 2.11701 3.58507 2.85104 2.85104C3.58507 2.11701 4.48194 1.75 5.54167 1.75C6.60139 1.75 7.49826 2.11701 8.23229 2.85104C8.96632 3.58507 9.33333 4.48194 9.33333 5.54167C9.33333 5.96944 9.26528 6.37292 9.12917 6.75208C8.99306 7.13125 8.80833 7.46667 8.575 7.75833L12.25 11.4333L11.4333 12.25ZM5.54167 8.16667C6.27083 8.16667 6.89063 7.91146 7.40104 7.40104C7.91146 6.89063 8.16667 6.27083 8.16667 5.54167C8.16667 4.8125 7.91146 4.19271 7.40104 3.68229C6.89063 3.17188 6.27083 2.91667 5.54167 2.91667C4.8125 2.91667 4.19271 3.17188 3.68229 3.68229C3.17188 4.19271 2.91667 4.8125 2.91667 5.54167C2.91667 6.27083 3.17188 6.89063 3.68229 7.40104C4.19271 7.91146 4.8125 8.16667 5.54167 8.16667Z" fill="#0C3967" />
                                            </svg>
                                            <input type="text" name="" id="" placeholder='Search pairs' value={search ? search : ""} onChange={(e) => setSearch(e.target.value)} />
                                        </div>
                                    </form>

                                    <div className={style.allPairs}>
                                        {
                                            filteredTokens.map((eachPair) => {
                                                return (
                                                    <>
                                                        <div className={style.pairStyle} onClick={
                                                            (e) => {
                                                                e.preventDefault();
                                                                setCurrentPair(eachPair);
                                                                setShowPairList_01(!showPairList_01);
                                                            }
                                                        }>
                                                            <img src={eachPair.tokenAimg} alt="" />
                                                            <p>{eachPair.tokenA}</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M14 18L12.6 16.55L16.15 13H4V11H16.15L12.6 7.45L14 6L20 12L14 18Z" fill="white" />
                                                            </svg>
                                                            <p>{eachPair.tokenB}</p>
                                                            <img src={eachPair.tokenBimg} alt="" />
                                                        </div>

                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </>
                        ) :
                            (
                                <></>
                            )
                    }

                    <div className={styles.box2}>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(parseFloat(e.target.value))}
                            placeholder="0.0"
                            required
                        />
                    </div>
                </div>

                <div className={styles.input_area}>
                    <div className={styles.box1}>
                        <p className={styles.t1}>You are receiving</p>
                        <div className={styles.token_contract} onClick={(e) => { setShowPairList_02(!showPairList_02) }}>
                            <img className='icon' src={currentPair.tokenBimg} />
                            <p className={styles.t2}>{currentPair.tokenB}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill="#ACCFFB" />
                            </svg>
                        </div>
                    </div>


                    {
                        showPairList_02 ? (
                            <>
                                <div className={style.dropdownSwappables}>
                                    <form action="" className={style.searchPair}>
                                        <div className={style.search_area}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M11.4333 12.25L7.75833 8.575C7.46667 8.80833 7.13125 8.99306 6.75208 9.12917C6.37292 9.26528 5.96944 9.33333 5.54167 9.33333C4.48194 9.33333 3.58507 8.96632 2.85104 8.23229C2.11701 7.49826 1.75 6.60139 1.75 5.54167C1.75 4.48194 2.11701 3.58507 2.85104 2.85104C3.58507 2.11701 4.48194 1.75 5.54167 1.75C6.60139 1.75 7.49826 2.11701 8.23229 2.85104C8.96632 3.58507 9.33333 4.48194 9.33333 5.54167C9.33333 5.96944 9.26528 6.37292 9.12917 6.75208C8.99306 7.13125 8.80833 7.46667 8.575 7.75833L12.25 11.4333L11.4333 12.25ZM5.54167 8.16667C6.27083 8.16667 6.89063 7.91146 7.40104 7.40104C7.91146 6.89063 8.16667 6.27083 8.16667 5.54167C8.16667 4.8125 7.91146 4.19271 7.40104 3.68229C6.89063 3.17188 6.27083 2.91667 5.54167 2.91667C4.8125 2.91667 4.19271 3.17188 3.68229 3.68229C3.17188 4.19271 2.91667 4.8125 2.91667 5.54167C2.91667 6.27083 3.17188 6.89063 3.68229 7.40104C4.19271 7.91146 4.8125 8.16667 5.54167 8.16667Z" fill="#0C3967" />
                                            </svg>
                                            <input type="text" name="" id="" placeholder='Search pairs' value={search ? search : ""} onChange={(e) => setSearch(e.target.value)} />
                                        </div>
                                    </form>

                                    <div className={style.allPairs}>
                                        {
                                            filteredTokens.map((eachPair) => {
                                                return (
                                                    <>
                                                        <div className={style.pairStyle} onClick={
                                                            (e) => {
                                                                e.preventDefault();
                                                                setCurrentPair(eachPair);
                                                                setShowPairList_02(!showPairList_02);
                                                            }
                                                        }>
                                                            <img src={eachPair.tokenAimg} alt="" />
                                                            <p>{eachPair.tokenA}</p>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M14 18L12.6 16.55L16.15 13H4V11H16.15L12.6 7.45L14 6L20 12L14 18Z" fill="white" />
                                                            </svg>
                                                            <p>{eachPair.tokenB}</p>
                                                            <img src={eachPair.tokenBimg} alt="" />
                                                        </div>

                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        ) :
                            (
                                <></>
                            )
                    }
                    <div className={styles.box2}>
                        <input
                            type="number"
                            placeholder="0.0"
                            value={amount * currentPair.exchangeRate}
                            readOnly
                        />
                    </div>



                </div>
                {

                    !stopSwap ?
                        (
                            !connected ? (
                                <WalletMultiButton style={buttonStyle2} />
                            ) : (
                                whiteListStatus ? (
                                    <button type="submit" className="swap-button">Swap</button>
                                ) : (
                                    <div className="swap-button" style={{ backgroundColor: "rgba(255, 0, 0, 0.418)" }}>You are not in the whitelist.</div>
                                )

                            )
                        ) : (
                            <div className="swap-button" style={{ backgroundColor: "rgba(255, 0, 0, 0.418)" }}>{swapMessage}</div>
                        )
                }

            </form>

        </div>: <>
            <div className="form-container">


            </div >

        </>
    );
};


export default SwapComponent

